import Vue from 'vue'
import VueRouter from 'vue-router'
import requireAuth from '../js/requireAuth.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    name: 'home',
    meta: {
      title: 'Xe tiện chuyến Sài Gòn Vũng Tàu',
    },
    component: () => import('../components/client/Main.vue')
  },
  {
    path: '/xe-tien-chuyen-sai-gon-vung-tau',
    name: 'gioithieu',
    meta: {
      title: 'Giới thiệu xe tiện chuyến',
    },
    component: () => import('../components/client/About.vue')
  },
  {
    path: '/xe4cho',
    name: 'xe4cho',
    meta: {
      title: 'Xe tiện chuyến 4 chỗ',
    },
    component: () => import('../components/client/Car_4.vue')
  },
  {
    path: '/xe7cho',
    name: 'xe7cho',
    meta: {
      title: 'Xe tiện chuyến 7 chỗ',
    },
    component: () => import('../components/client/Car_7.vue')
  },
  {
    path: '/xe16cho',
    name: 'xe16cho',
    meta: {
      title: 'Xe tiện chuyến 16 chỗ',
    },
    component: () => import('../components/client/Car_16.vue')
  },
  {
    path: '/lienhe',
    name: 'lienhe',
    meta: {
      title: 'Liên hệ xe tiện chuyến',
    },
    component: () => import('../components/client/Contac.vue')
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('../components/admin/Login.vue'),
    meta: {
      title: 'Đăng nhập',
    },
  },
  {
    path: '/Admin',
    name: 'Admin',
    meta: {
      title: 'Administrator',
    },
    component: () => import('../components/admin/Admin.vue'),
    beforeEnter: requireAuth,
  },
  
]

const router = new VueRouter({
  routes,
  mode: 'history',
})

router.beforeEach((to, from, next) => {
  // let title = 'Xe tiện chuyến Sài Gòn Vũng Tàu';
  // if (to.meta.title) {
  //   title += ' | ' + to.meta.title;
  // }
  // document.title = title;
  window.scrollTo(0, 0);
  if (to.matched.length === 0) {
    next('/');
  } else {
    next();
  }
});

export default router
